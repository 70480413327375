import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import Calendar from 'rev.sdk.js/Components/Calendar';
import {DT} from 'rev.sdk.js/Components/Calendar/CalendarUtil';
import constants from '../../constants';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import * as Ant from 'antd';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
const qs = require('query-string');

const defaultValue = {
  qty: 0,
  date: '',
  room: '',
};

function DateCell(props) {
  const {
    date,
    classNames: _classNames,
    selected,
    onClick,
    stock,
    defaultQuantity = 0,
  } = props;

  let classNames = [];

  return (
    <DateCellWrapper
      // key={date}
      className={_classNames.concat(classNames).join(' ')}
      onClick={(e) => {
        if (!_classNames.concat(classNames).includes('disabled')) {
          onClick(e);
        }
      }}>
      {date}
      <Ant.Tag className="stock" color={stock ? 'cyan' : 'geekblue'}>
        {stock ? stock.qty : defaultQuantity}間
      </Ant.Tag>
    </DateCellWrapper>
  );
}

const DateCellWrapper = styled.div`
  position: relative;

  &.r-today::after {
    content: ' ';
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${constants.THEME_COLOR_SECOND};
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  & > .stock {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
`;

export default function ProductStock(props) {
  const {location} = props;
  const {productId} = qs.parse(location.search);
  const [selected, setSelected] = useState(); // Date format
  const [roomControl, setRoomControl] = useState();
  const [product, setProduct] = useState();
  const [roomOrders, setRoomOrders] = useState();
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });

  const getRecords = useCallback(async () => {
    if (productId) {
      AppActions.setLoading(true);
      try {
        const first = DT({year: filters.year, month: filters.month, date: 1});
        const last = DT({
          year: filters.year,
          month: filters.month + 1,
          date: 0,
        });

        let resp = await JStorage.fetchAllDocuments(
          'room_control',
          {
            room: productId,
            date: {
              $gte: first.toLocaleDateString('sv'),
              $lte: last.toLocaleDateString('sv'),
            },
          },
          {date: 1},
        );

        let current = DT({...filters, date: 1});
        let result = {};
        for (let i = 1; i <= last.getDate(); i++) {
          current.setDate(i);
          result[i] = resp.find(
            (x) => x.date === current.toLocaleDateString('sv'),
          );
        }

        setRoomControl(result);
      } catch (err) {
        console.warn(err);
      }
      AppActions.setLoading(false);
    }
  }, [productId, filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  const getRoomOrders = useCallback(async (selected) => {
    setRoomOrders(); //reset
    if (selected) {
      try {
        let resp = await JStorage.fetchAllDocuments('room_order', {
          'items.product.id': {$oid: selected.room},
          'items.config.from_date': {$lte: selected.date},
          'items.config.to_date': {$gt: selected.date},
        });
        setRoomOrders(resp);
      } catch (err) {
        console.warn(err);
      }
    }
  }, []);

  useEffect(() => {
    if (productId) {
      (async () => {
        try {
          let resp = await JStorage.fetchOneDocument('product', {
            id: productId,
          });
          setProduct(resp);
        } catch (err) {
          console.warn(err);
        }
      })();
    }
  }, [productId]);

  const submit = useCallback(
    async (record) => {
      AppActions.setLoading(true);
      try {
        if (record.id) {
          //edit
          await JStorage.updateDocument(
            'room_control',
            {id: record.id},
            record,
          );
        } else {
          //create
          let resp = await JStorage.createDocument('room_control', record);
          setSelected(resp);
        }
        Ant.message.success('更新成功');
        getRecords();
      } catch (err) {
        alert('發生錯誤');
        console.warn(err);
      }
      AppActions.setLoading(false);
    },
    [getRecords],
  );

  return (
    <Wrapper>
      <div className="header">
        <button
          className="back-button"
          onClick={() => AppActions.navigate('/admin/products')}>
          <ChevronLeft size={32} color="#000" />
          返回
        </button>
        <h1>訂房庫存</h1>
      </div>
      <div className="content">
        <div>
          <h3 style={{marginBottom: 30}}>{product?.name}</h3>
          {!roomControl && <Ant.Alert message="取得庫存資料中" />}
          {selected && (
            <React.Fragment>
              <div className="row">
                <label>庫存編號</label>
                <div>{selected.id}</div>
              </div>

              <div className="row">
                <label>日期</label>
                <div>{selected.date}</div>
              </div>

              <div className="row">
                <label>庫存量</label>
                <Ant.Input
                  type="number"
                  value={selected.qty}
                  onChange={(e) =>
                    setSelected((prev) => ({
                      ...prev,
                      qty: parseInt(e.target.value),
                    }))
                  }
                />
              </div>

              <div className="row">
                <div style={{flex: 1}}></div>
                <Ant.Button type="primary" onClick={() => submit(selected)}>
                  儲存
                </Ant.Button>
              </div>

              <div className="row">
                <Ant.Tag color="cyan">已建立庫存資料</Ant.Tag>
                <Ant.Tag color="geekblue">未建立庫存資料</Ant.Tag>
              </div>

              <Ant.Divider />

              {roomOrders &&
                roomOrders.map((x, key) => (
                  <Card key={key}>
                    <div className="title">
                      入住時間: {x.items[0].config.from_date} ~{' '}
                      {x.items[0].config.to_date}
                    </div>
                    <div>客戶姓名: {x.buyer_name}</div>
                    <div>客戶手機: {x.buyer_phone}</div>
                    <div>客戶信箱: {x.buyer_email}</div>
                    <div>
                      付款狀態:{' '}
                      {{
                        success: '付款完成',
                        failure: '付款失敗',
                      }[x.payment_status] || '未付款'}
                    </div>
                    <div>
                      成人人數: {x.adults} / 兒童人數: {x.kids}
                    </div>
                    {x.items.map((item, idx) => (
                      <div key={idx}>
                        {idx + 1}. 房間名稱: {item.name} / {item.config.qty} 間
                      </div>
                    ))}
                  </Card>
                ))}
            </React.Fragment>
          )}
        </div>
        <div className="rev-calendar-container">
          <Calendar
            year={filters.year}
            month={filters.month}
            onSelect={(data) => {
              console.log('DBG', data);

              getRoomOrders(roomControl?.[data.date]);
              if (roomControl?.[data.date]) {
                //edit
                setSelected(roomControl[data.date]);
              } else {
                //create
                setSelected({
                  ...defaultValue,
                  room: productId,
                  date: data.dt.toLocaleDateString('sv'),
                  qty: product.quantity || defaultValue.qty,
                });
              }
            }}
            renderDate={(props) => (
              <DateCell
                key={props.date}
                selected={selected}
                stock={roomControl?.[props.date]}
                defaultQuantity={product?.quantity}
                {...props}
              />
            )}
            onClickNext={(next) => {
              setRoomControl(null);
              setFilters(next);
            }}
            onClickPrev={(prev) => {
              setRoomControl(null);
              setFilters(prev);
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > .header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 32px;

    & > h1 {
      margin: 0;
    }

    & > .back-button {
      border: 0;
      background-color: #fff;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  & > .content {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 360px 1fr;

    & .rev-calendar-container {
      width: 100%;
      max-width: 600px;
      margin: 0 auto 60px auto;
      border: solid 1px #bbb;
      & .r-date {
        position: relative;
      }

      & .r-cell.r-date.r-today {
        border: solid 1px #fff;
      }

      & .r-cell.r-date:hover,
      .r-next:hover,
      .r-prev:hover,
      .r-cell.r-date.r-selected {
        background-color: #ccedfc;
        border: solid 1px #ccedfc;
      }
    }

    & .row {
      display: flex;
      margin-bottom: 20px;

      & > label {
        width: 80px;
        flex-shrink: 0;
      }
    }
  }
`;

const Card = styled.div`
  padding: 8px 12px;
  margin-bottom: 15px;
  color: #707070;
  border: solid 1px #ddd;
  border-radius: 6px;

  & > .title {
    font-weight: bold;
  }
`;
